const Joke = ({ setup, punchline }) => {
  return (
    <div>
      {setup !== "N/A" && <blockquote>{setup}</blockquote>}
      <blockquote>{punchline}</blockquote>
    </div>
  );
};

export default Joke;
