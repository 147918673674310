import "./App.css";
import { useEffect, useState } from "react";
import Joke from "./components/Joke";

function App() {
  const [setup, setSetup] = useState();
  const [joke, setJoke] = useState();
  const [showModal, setShowModal] = useState(false);
  const [installVisible, setInstallVisible] = useState(false);
  const [deferredPrompt, setPrompt] = useState(null);

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/worker.js").then(function () { });
  }

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", function (event) {
      event.preventDefault();
      setInstallVisible(true);
      if (event) setPrompt(event);
    });
  }, []);

  function showPromptModal() {
    if (deferredPrompt) deferredPrompt.prompt();

    deferredPrompt.userChoice.then(function (choiceResult) {
      if (choiceResult.outcome === "dismissed") {
      } else {
        console.log("User added to home screen");
        setInstallVisible(false);
      }
    });
    setPrompt(null);
  }

  const fetchChuckNorrisJoke = () => {
    setSetup("N/A");
    setJoke(" ");
    fetch("https://api.chucknorris.io/jokes/random")
      .then((response) => response.json())
      .then((response) => setJoke(response.value))
      .catch((err) => console.error(err));
  };

  const fetchJoke = (param) => {
    setShowModal(false);
    setSetup("N/A");
    setJoke(" ");
    if (param === "Trigger") setShowModal(true);
    var fetchUrl = "https://v2.jokeapi.dev/joke/" + param;
    fetch(fetchUrl)
      .then((response) => response.json())
      .then((response) => 
      { 
        displayV2Joke(response); 
        saveJoke(response) 
      })
      .catch((err) => console.error(err));
  };

  const displayV2Joke = (response) => {
    if (response.type === "twopart") {
      setSetup(response.setup);
      setJoke(response.delivery);
    } else {
      setSetup("N/A");
      setJoke(response.joke);
    }
  };

  const saveJoke = (response) => {
    const joke = {
      error: response.error,
      category: response.category,
      type: response.type,
      joke: response.joke,
      setup: response.setup,
      delivery: response.delivery,
      flags: {
        nsfw: response.flags.nsfw,
        religious: response.flags.religious,
        political: response.flags.political,
        racist: response.flags.racist,
        sexist: response.flags.sexist,
        explicit: response.flags.explicit
      },
      id: response.id,
      safe: response.safe,
      lang: "en"
    }

    fetch('https://jasoft-001-site4.atempurl.com/api/Jokes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(joke)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });

  }

  let banner = installVisible ? (
    <button className="button btn-install" onClick={() => showPromptModal()}>
      Install App
    </button>
  ) : (
    <div />
  );

  var modal = showModal && (
    <div className="modal">
      <h4>
        Trigger Warning! Some people may find dark jokes to be very offensive!
      </h4>
      <div className="modal-buttons">
        <button onClick={() => setShowModal(false)} className="btn-cancel">
          Better not then
        </button>
        <button onClick={() => fetchJoke("Dark")} className="btn-confirm">
          Show me anyway!
        </button>
      </div>
    </div>
  );

  return (
    <section className="container">
      <div className="content">
        <div className="title">
          <h1>Find a Joke!</h1>
        </div>

        <div className="buttons">
          <button onClick={fetchChuckNorrisJoke}>Chuck Norris</button>
          <button onClick={() => fetchJoke("Pun")}>Pun!</button>
          <button onClick={() => fetchJoke("Spooky,Christmas")}>Halloween / Christmas</button>
          <button onClick={() => fetchJoke("Trigger")}>Dark</button>
          <button onClick={() => fetchJoke("Miscellaneous")}>Random</button>
        </div>
        {showModal && modal}
        <div className="joke-container">
          <Joke setup={setup} punchline={joke} />
        </div>
      </div>
      <div className="footer">
        <div className="install">{banner}</div>
        <span>
          &copy;&nbsp;
          <a href="https://www.joseph.mt/" target="blank">
            www.joseph.mt
          </a>
          &nbsp;2023
        </span>
      </div>
    </section>
  );
}

export default App;
